<template>
  <div>
    <loading v-if="loadingModalActive"/>
  <PhoneAuthModal v-if="loginModal" v-on:closeModal="closeLoginModal"/>
  <info-dialog v-if="infoModalActive" :modal-message="modalMessage" v-on:closeModal="closeModal"/>
  <confirm-dialog :modal-message="modalMessage" v-if="confirmActive" v-on:confirm="confirm" v-on:cancel="cancel"/>
  <div class="container-xxl" style="background-color: var(--background-color);" v-if="currentProduct">
    <div class="container-xxl position-sticky shadow-sm" style="top:50px; z-index: 4000; background-color: var(--background-color);">

    </div>
    <div class="container-xxl">
      <div class="row mt-5">
        <div class="col-12 position-fixed pt-2" style="z-index: 100;background-color: var(--white);border-bottom: 1px solid var(--light);">
          <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb ">
              <router-link class="breadcrumb-item" :to="{name:'Shops',params:{city:this.$route.params.city}}">Shop</router-link>
              <router-link class="breadcrumb-item" :to="{name:'ShopInfo',params:{city:this.$route.params.city,shop_name:currentShop.name.split(' ').join('-'),product_type:'All'}}">{{currentShop.name}}</router-link>
              <router-link class="breadcrumb-item" :to="{name:'ShopInfo',params:{city:this.$route.params.city,shop_name:currentShop.name.split(' ').join('-'),product_type:currentProduct.type.split(' ').join('-')}}">{{currentProduct.type}}</router-link>
              <li class="breadcrumb-item active text-truncate" aria-current="page">{{ currentProduct.itemName }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row mt-3 pt-5 justify-content-center">

        <div class="col-12 col-lg-5 col-md-6 mb-1">
          <div>
            <div id="carouselExampleControls" class="carousel border-secondary border-2 rounded slide" data-bs-ride="carousel">
              <div id="carouselExampleIndicators" class="carousel slide mx-auto" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" v-for="(img,index) in currentProduct.images" :key="index" data-bs-target="#carouselExampleIndicators" :data-bs-slide-to="index" :class="index===0?'active':''" :aria-current="index===0?true:false" ></button>
                </div>
                <div class="carousel-inner rounded" v-if="currentProduct.images.length>0"    >
                  <div class="carousel-item" data-bs-interval="4000" :class="index===0?'active':''"  v-for="(img, index) in currentProduct.images" :key="index">
                    <img :src="img" class="carousel-img">
                  </div>
                </div>
                <div class="carousel-inner rounded" v-else>
                  <div class="carousel-item">
                    <img src="../assets/flash/1.png" class="carousel-img">
                  </div>
                </div>

                <div class="carousel-caption text-start w-80 mt-3 top-0 p-0" v-if="currentProduct.discount>0">
                  <span class="badge bg-danger rounded text-white mb-1" style="position: absolute" >{{currentProduct.discount}}%</span>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span class="bg-secondary rounded-circle p-1" style="width: 40px;height: 40px;" aria-hidden="true"><i class="fas fa-angle-left fa-2x"></i></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span class="bg-secondary rounded-circle p-1" style="width: 40px;height: 40px;" aria-hidden="true"><i class="fas fa-angle-right fa-2x"></i></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div class="card p-0 rounded m-0 rounded mt-2"  v-if="currentProduct.images.length>0">
              <div class="card-body m-0 d-flex justify-content-center align-items-center p-0" style="background-color: var(--light);">
                <div class="scrolling-wrapper mb-0 ">
                  <ul class="text-start d-block list-unstyled d-flex">
                    <li v-for="(img, index) in currentProduct.images" :key="index" class="d-inline-block m-1">
                      <img :src="img" class="rounded " style="width:50px;height:50px;object-fit:cover;">
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="col-12 col-lg-5 rounded col-md-6 mb-1"  style="background-color: var(--light);" >
          <div class="card-body p-2" style="background-color: var(--light); color: var(--text-color);" >
            <div class="d-flex justify-content-between align-items-center">
              <p class="badge rounded-pill" style="background-color: var(--secondary);">{{currentProduct.type}}</p>
            </div>
            <div class="fw-bold d-flex flex-column">
              <div class="text-start d-inline-block">
                <p class="fs-5 ">{{currentProduct.itemName}} </p>
                <div v-if="currentShop.showStock=='yes'" class="text-black">
                  <span class="text-black-50" style="font-size: 12px;" v-if="currentProduct.stock>0">{{currentProduct.stock}} {{$t('message.item_available')}}</span>
                  <span class="text-danger" style="font-size: 12px;" v-else>Out of stock</span>
                </div>
                <div>
                  <div v-if="currentProduct.discount==='0'">
                    <span class="fs-4" style="color: var(--primary);">{{currentProduct.sellPrice.toLocaleString()}} Ks</span>
                  </div>
                  <div v-else class="d-flex flex-column ">
                    <span style="text-decoration: line-through !important;">{{currentProduct.sellPrice.toLocaleString()}} Ks</span>
                    <span class="text-danger fw-bold fs-4" style="color: var(--primary);">{{currentProduct.sellPrice-currentProduct.sellPrice*(currentProduct.discount/100)}} Ks</span>
                  </div>
                </div>
                <p class="mt-1">{{ $t('message.size') }} - {{currentProduct.size}}</p>
                <p>{{$t('message.color')}} - {{currentProduct.color}}</p>
              </div>
              <button class="btn w-100" style="background-color: var(--btnLight);" :id="currentProduct.id" v-if="currentProduct.stock>0" :disabled="currentProduct.stock<=0" @click="addToCart(currentProduct)">
                <span><i class="fas fa-cart-plus"></i> {{ $t('message.add_to_cart') }}</span><div class="spinner-border spinner-border-sm mx-auto d-inline-block" v-if="loadingActive"></div>
              </button>
              <button class="btn w-100" style="background-color: var(--btnLight);" :id="currentProduct.id" v-else @click="addToCart(currentProduct)">
                <span><i class="fas fa-cart-plus"></i> {{ $t('message.add_to_cart') }}</span><div class="spinner-border spinner-border-sm mx-auto d-inline-block" v-if="loadingActive"></div>
              </button>
            </div>
          </div>

        </div>
        <div class="col-12 col-lg-2 align-items-center ">

          <div class=" ">
            <div class="my-2 d-flex d-lg-block align-items-center">
              <img :src="currentShop.profileUrl" style="width:200px; height: 200px; border-radius: var(--border-radius); object-fit: cover" alt="">

              <div>
                <h6 class="text-black-50">{{$t('message.welcome')}}</h6>
                <h6 style="color: var(--primary);">{{currentShop.name}}</h6>
                <div>
                  <span class="badge rounded-pill bg-secondary me-1" v-for="(type,index) in currentShop.shopType" :key="index">{{type}}</span>
                </div>
              </div>
            </div>
            <h3>{{$t('message.nav_contact')}}</h3>
            <div class="divider"></div>
            <span><i class="fas fa-envelope"></i> {{currentShop.email}}</span>
            <span><i class="fas fa-phone"></i>{{currentShop.phone}}</span>
            <span><i class="fas fa-location-dot"></i> {{currentShop.address}}</span>
          </div>
        </div>

        <div class="col-12 mx-auto">
          <div style="color: var(--text-color);">
            <p>Description:</p>
            <div class="d-flex justify-content-center align-items-center" v-if="currentProduct.youtubeLink">
              <iframe width="560" height="315" :src="'https://www.youtube.com/embed/'+`${currentProduct.youtubeLink}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>{{currentProduct.description}}</p>
          </div>
        </div>

        <div class="col-12 border-1 border-top">
          <div class="row">
            <div>
              <h3 class="my-3" style="color: var(--primary);">Related Products.</h3>
            </div>
            <div class="col-6 col-lg-2 col-md-3 m-0 p-0" v-for="product in relatedProduct" :key="product.id">
              <product-item-card :user="user" :product="product" @selectedProduct="showSelectedProduct" :city="this.$route.params.city" :shop_id="shop_id"/>
            </div>
            <div class="text-center my-4">
              <router-link class="breadcrumb-item" :to="{name:'ShopInfo',params:{city:this.$route.params.city,shop_name:currentShop.name.split(' ').join('-'),product_type:currentProduct.type.split(' ').join('-')}}">{{$t('message.seeMore')}}</router-link>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
  </div>
</template>

<script>
import db from "@/config/FirebaseInit";
// import AddToCartBar from "@/components/AddToCartBar";
import ProductItemCard from "@/components/ProductItemCard";
import InfoDialog from "@/components/InfoDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import PhoneAuthModal from "@/components/PhoneAuthModal";
import Loading from "@/components/Loading";
export default {
  components: {PhoneAuthModal, Loading, ConfirmDialog, InfoDialog, ProductItemCard},
  data(){

    return{
      city:'',
      currentProduct:'',
      addToCartOrder:'',
      relatedProduct:[],
      currentShop:'',
      count:1,
      modalMessage:'',
      loadingActive:false,
      loadingModalActive:false,
      confirmActive:false,
      infoModalActive:false,
      loginModal:false,
    }
  },

  /*beforeMount() {
    this.city=this.$route.params.city;
    this.shop_name=this.$route.params.shop_name.split('-').join(' ');
    this.product_id=this.$route.params.product_id;
  },*/


  async mounted(){
    /*window.onpopstate = function(event) {
      document.location.replace(`/${this.$route.params.city}/${this.currentShop.name.split(' ').join('-')}/All`);
      alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
      let state = JSON.stringify(event.state);
      state.back = `/${this.$route.params.city}/${this.currentShop.name.split(' ').join('-')}/All`
    };*/
    await db.collection(this.$route.params.city).where('name','==',this.$route.params.shop_name.split('-').join(' ')).onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        this.currentShop = doc.data();
      });
      if(this.currentShop.onlineSell !== 'no'){
        db.collection(this.$route.params.city).doc(this.currentShop.id).collection('products').doc(this.$route.params.product_id).onSnapshot(snapshot => {
          this.currentProduct=snapshot.data();
          // this.relatedProduct.splice(0,this.relatedProduct.length);
          this.relatedProduct=[];
          db.collection(this.$route.params.city)
              .doc(this.currentShop.id)
              .collection('products')
              .where('type','==',this.currentProduct.type)
              .limit(12)
              .onSnapshot((snapshot)=>{
                snapshot.forEach(doc=>{
                  if(doc.data().id!==this.currentProduct.id){
                    this.relatedProduct.push(doc.data())
                  }
                })
              })
        })
      }

    });
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    cartInfo(){
      return this.$store.getters.getCartInfo;
    },
    orders(){
      return this.$store.getters.getCartItems;
    },
  },

  methods:{
    showSelectedProduct(e){
      this.currentProduct = e;
    },
    async addToCart(product){
      if(this.user){
        this.loadingActive = true;

        //obj for user add to cart
        const orderItem = {
          'productId': product.id,
          'image':product.images[0],
          'orderUserId':this.user.id,
          'count':1,
          'itemName':product.itemName,
          'itemCode':product.itemCode,
          'buyPrice':product.buyPrice,
          'sellPrice':product.sellPrice,
          'discount':product.discount,
        }
        if(product.images.length>0){
          orderItem.image = product.images[0];
        }else {
          orderItem.image = 'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/default%2F1.png?alt=media&token=b935b93f-8b63-431b-93bd-ef52bcb2f3e0'
        }

        //if cartInfo is not exists in user,
        if(!this.cartInfo){
          await db.collection('users').doc(this.user.id).collection('cart').doc('shop').set({
            'shopId':this.currentShop.id,
          }).then(()=>{
            db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').doc(product.id).set(orderItem).then(()=>{
              this.loadingActive=false;
              this.$store.dispatch("getCartItems", this.currentShop.id);
            })
          })
        }else { //if cart info is already existed in user
          if(this.currentShop.id === this.cartInfo.shopId){ //check whether the user cart info shop is same with current shop.
            if (!this.orders.some((order)=>order.productId === product.id)){ //check whether the product is already selected
              await db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').doc(product.id).set(orderItem).then(()=>{
                this.loadingActive=false;
                this.$store.dispatch("getCartItems", this.shop_id);
              });
             /* await db.collection('users').doc(this.user.id).collection('cart').doc('shop').set({
                'shopId':this.shop_id,
              }).then(()=>{

              });*/
            }else {
              this.modalMessage="Already Selected";
              this.loadingActive = false;
              this.infoModalActive = true;
            }
          }else {
            this.modalMessage="Do you want to remove items from previous Shopp ";
            this.loadingActive = false;
            this.addToCartOrder = orderItem;
            this.confirmActive = true;
          }
        }


        /*if(!this.orders.some((order)=>order.productId === orderItem.productId)){
          this.orders.push(orderItem);
          this.cartOrders.push(orderItem);
        }else {
          this.loadingActive = false;
          this.infoModalActive = true;
          this.modalMessage="Already selected the product!";
        }*/

      }
      else {
        this.loginModal =true;
      }
      this.$store.dispatch("getCartInfo");
    },

    async confirm(){
      this.confirmActive=false;
      this.loadingActive = true;
      await db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').get().then((snapshot)=>{
        snapshot.forEach(doc=>{
          doc.ref.delete();
        });
        db.collection('users').doc(this.user.id).collection('cart').doc('shop').delete().then(()=>{
          db.collection('users').doc(this.user.id).collection('cart').doc('shop').set({
            'shopId':this.currentShop.id,
          }).then(()=>{
            db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').doc(this.addToCartOrder.id).set(this.addToCartOrder).then(()=>{
              this.loadingActive=false;
              console.log('added')
              this.$store.dispatch("getCartItems", this.currentShop.id);
            });
          });
          // this.infoModalActive = true;
          // this.modalMessage = "Already Removed! You can add item now.";
        });

      });
    },
    cancel(){
      this.confirmActive = !this.confirmActive

    },
    closeLoginModal(){
      this.loginModal = !this.loginModal;
    },
    closeModal(){
      this.infoModalActive = !this.infoModalActive;
    },
  }
}
</script>

<style scoped>
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.carousel{
  margin:auto;
  background-color: white;
  width: 100%;
}
.carousel-item{
  height: 450px;
}
.carousel-inner{
  height: 400px;
}
.carousel-img{
  background-size: cover;
  margin: 0;
  border-radius: var(--border-radius);
  width: 100%;
}
.breadcrumb-item{
  text-decoration: none;
  cursor: pointer;
}

.carousel-img-no{
  margin: 0;
  border-radius: var(--border-radius);
  width: 400px;
  height: 400px;

}
@media screen and (max-width: 1100px){
  .carousel{
    width: 100%;
  }
  .carousel-item{
    height: inherit;
  }
  .carousel-img{
    width: 100%;
    height: inherit;
    object-fit: cover;
  }
}
</style>